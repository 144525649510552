<template>
  <section id="login">
    <v-container>
      <v-row>
        <v-col
          lg="5"
          style="margin: 0 auto;"
        >
          <div class="grey-box text-center px-16">
            <img
              src="@/assets/img/small-logo.svg"
              alt=""
            >

            <div class="text-h5 font-weight-bold">
              Изменение пароля
            </div>

            <v-form
              ref="passwordChange"
              @submit.prevent="changePassword"
            >
              <v-row class="text-left">
                <v-col
                  cols="12"
                  lg="12"
                >
                  <label for="">Старый пароль</label>
                  <v-text-field
                    v-model="oldPassword"
                    color="#32a1af"
                    background-color="#fff"
                    solo
                    hide-details="auto"
                    flat
                    :append-icon="hideOldPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                    :type="hideOldPassword ? 'password' : 'text'"
                    :rules="oldPasswordRules"
                    autocomplete="do-not-autofill"
                    @click:append="() => (hideOldPassword = !hideOldPassword)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="12"
                  class="py-0"
                >
                  <label for="">Пароль</label>
                  <v-text-field
                    v-model="newPassword"
                    color="#32a1af"
                    background-color="#fff"
                    solo
                    hide-details="auto"
                    flat
                    :append-icon="hideNewPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                    :type="hideNewPassword ? 'password' : 'text'"
                    :rules="newPasswordRules"
                    autocomplete="do-not-autofill"
                    @click:append="() => (hideNewPassword = !hideNewPassword)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="12"
                >
                  <label for="">Повторите пароль</label>
                  <v-text-field
                    v-model="newPasswordDuplicate"
                    color="#32a1af"
                    background-color="#fff"
                    solo
                    hide-details="auto"
                    flat
                    :append-icon="hideNewPasswordDuplicate ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                    :type="hideNewPasswordDuplicate ? 'password' : 'text'"
                    :rules="newPasswordDuplicateRules"
                    @click:append="() => (hideNewPasswordDuplicate = !hideNewPasswordDuplicate)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="12"
                  class="text-center"
                >
                  <v-btn
                    color="#333"
                    dark
                    large
                    type="submit"
                    :loading="loading"
                    style="width:100%;"
                  >
                    Изменить пароль
                  </v-btn>
                  <div
                    v-if="errorMessage !== '' "
                    class="red--text mt-2"
                  >
                    {{ errorMessage }}
                  </div>
                  <div class="mt-2">
                    <v-btn
                      text
                      @click="$router.back()"
                    >
                      <v-icon small>
                        mdi-arrow-left
                      </v-icon> Назад
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from 'axios'
import {AUTH} from '@/http-routes'

export default {
  data() {
    return {
      oldPasswordRules:[
        v => !!v || 'Поле должно быть заполнено'
      ],
      newPasswordRules: [
        v => !!v || 'Поле должно быть заполнено'
      ],
      newPasswordDuplicateRules: [
        v => !!v || 'Поле должно быть заполнено',
        v => v === this.newPassword || 'Пароли не совпадают'
      ],
      oldPassword: '',
      newPassword:'',
      newPasswordDuplicate:'',
      errorMessage: '',
      loading: false,
      hideOldPassword:true,
      hideNewPassword: true,
      hideNewPasswordDuplicate: true
    }
  },
  methods: {
    changePassword() {
      if (this.$refs.passwordChange.validate()) {
        this.loading = true
        axios
          .post(`${AUTH}user/changePassword`, {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword
          })
          .then(() => {
            this.$success("Пароль успешно изменен")
            this.$router.push('/')
          })
          .catch(err => {
            this.errorMessage = err.response.data
          }).finally(() => {
            this.loading = false
          })
      }
    },
  }
}
</script>
